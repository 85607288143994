import { render, staticRenderFns } from "./UpdateCompanyDetail.vue?vue&type=template&id=14a6e282&scoped=true&"
import script from "./UpdateCompanyDetail.vue?vue&type=script&lang=js&"
export * from "./UpdateCompanyDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "14a6e282",
  null
  
)

export default component.exports