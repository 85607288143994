<template>
	<div>
		<v-layout class="py-2">
			<div class="listing-heading">Branding</div>
			<v-spacer></v-spacer>
			<v-btn color="grey lighten-3" tile depressed class="mx-3" @click="goBack()">
				<v-icon small left>mdi-arrow-left</v-icon>
				Back
			</v-btn>
		</v-layout>
		<div class="branding-tab-details border">
			<div class="py-2 text-h6 px-3 grey lighten-4 fw-600 blue--text text--darken-4">
				PDF HEADER & FOOTER
			</div>
			<div class="px-3">
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Quotation Header </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload
							can-change
							v-model="quotation_header"
							branding_type="quotation_header"
						></BrandingUpload>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Purchase Header </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload can-change v-model="po_header" branding_type="po_header"></BrandingUpload>
					</v-col>
				</v-row>
			</div>

			<div class="px-3">
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Quotation Footer </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload
							can-change
							v-model="quotation_footer"
							branding_type="quotation_footer"
						></BrandingUpload>
					</v-col>
				</v-row>
				<v-row>
					<v-col md="3" class="my-auto">
						<span class="text-h6"> Purchase Footer </span>
					</v-col>
					<v-col md="9">
						<BrandingUpload can-change v-model="po_footer" branding_type="po_footer"></BrandingUpload>
					</v-col>
				</v-row>
			</div>
		</div>
	</div>
</template>
<script>
import BrandingUpload from "@/view/components/BrandingUpload";
import { GetBrandingsDetails } from "@/core/lib/setting.lib";

export default {
	name: "Branding",
	data() {
		return {
			quotation_header: null,
			po_header: null,
			quotation_footer: null,
			po_footer: null,
		};
	},
	components: {
		BrandingUpload,
	},
	methods: {
		getBranding() {
			//const _this = this;
			GetBrandingsDetails()
				.then((data) => {
					let brandingObj = {};

					for (let dataObj in data) {
						if (data[dataObj]) {
							brandingObj = data[dataObj];
							if (brandingObj.document_uuid) {
								switch (brandingObj.type) {
									case "quotation_header":
										this.quotation_header = brandingObj.document_url;
										break;
									case "po_header":
										this.po_header = brandingObj.document_url;
										break;
									case "quotation_footer":
										this.quotation_footer = brandingObj.document_url;
										break;
									case "po_footer":
										this.po_footer = brandingObj.document_url;
										break;
								}
							}
						}
					}
				})
				.catch(() => {
					this.goBack();
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
	},
	mounted() {
		this.getBranding();
	},
};
</script>
