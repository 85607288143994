<template>
	<div>
		<v-layout class="py-2">
			<v-tabs
				v-model="companyTab"
				background-color="transparent"
				color="blue"
				class="custom-tab-transparent w-100"
				active-class="blue darken-4 text-white"
				hide-slider
			>
				<v-tab href="#company_detaiils"
					><v-icon small left>mdi-information-outline</v-icon>Company Details</v-tab
				>
				<v-tab href="#business_hour"> <v-icon small left>mdi-repeat</v-icon>Business Hours</v-tab>
				<v-tab href="#holidays"><v-icon small left>mdi-timeline-clock-outline</v-icon>Holidays</v-tab>
			</v-tabs>
		</v-layout>
		<div class="compay-tab-details mt-2">
			<v-tabs-items v-model="companyTab">
				<v-tab-item value="company_detaiils">
					<div class="company_detaiils-item">
						<div class="sec-1 pa-4 blue lighten-5 d-flex">
							<div class="text-center mr-5">
								<div class="company-logo">
									<img v-if="company_logo" :src="company_logo" alt="bthrust" width="120" />
									<img v-else src="media/custom-svg/companylogo.svg" alt="" />
								</div>
								<v-btn
									:loading="uploading"
									:disabled="uploading"
									color="blue darken-4 white--text"
									small
									tile
									depressed
									class="fw-600 mx-auto mt-4"
									@click="changeImage()"
								>
									<span style="font-size: 13px">
										{{ company_logo ? "change" : "upload" }}
									</span>
								</v-btn>
							</div>
							<div class="company-details">
								<div class="sub-heading text-capitalize pb-5" v-if="companyDetails">
									{{ companyDetails.company_name }} ({{ companyDetails.employee_count }})
									<v-btn class="mx-2" icon fab small v-on:click="open_dialog.company_detail = true">
										<v-icon color="blue darken-4">mdi-pencil-circle</v-icon>
									</v-btn>
								</div>
								<div class="fw-500 mb-2" v-if="companyDetails">
									<v-icon class="mr-1" color="blue darken-4">mdi-email-outline</v-icon>
									{{ companyDetails.email_address }}
								</div>
								<div class="fw-500 mb-2" v-if="companyDetails">
									<v-icon class="mr-1" color="blue darken-4">mdi-phone-outline</v-icon>
									{{ companyDetails.phone_number }}
								</div>
								<div class="fw-500 mb-2" v-if="companyDetails">
									<v-icon class="mr-1" color="blue darken-4">mdi-web</v-icon>
									{{ companyDetails.website_url }}
								</div>
								<div class="fw-500 mb-2" v-if="companyDetails">
									<v-icon class="mr-1" color="blue darken-4">mdi-map-marker-outline</v-icon>
									{{ formatedAddress(companyDetails) }}
								</div>
							</div>
						</div>
						<div class="sec-2 fw-500 mt-5" v-if="false">
							<div class="sub-heading text-capitalize">
								Social Information
								<v-btn class="mx-2" icon fab small v-on:click="open_dialog.social_info = true">
									<v-icon color="blue darken-4">mdi-pencil-circle</v-icon>
								</v-btn>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Whatsapp</div>
								<div class="">{{ companyDetails.whatsapp_number }}</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Facebook</div>
								<div class="">{{ companyDetails.facebook_url }}</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Instagram</div>
								<div class="">{{ companyDetails.instagram_url }}</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Twitter</div>
								<div class="">{{ companyDetails.twitter_url }}</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Google +</div>
								<div class="">{{ companyDetails.google_plus_url }}</div>
							</div>
						</div>
						<div class="sec-2 fw-500 mt-5">
							<div class="sub-heading text-capitalize">
								Locale Information
								<v-btn class="mx-2" icon small fab v-on:click="open_dialog.local_information = true">
									<v-icon color="blue darken-4">mdi-pencil-circle</v-icon>
								</v-btn>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Date Format</div>
								<div class="">
									<template v-if="companyDetails.date_format">
										{{ companyDetails.date_format }}
									</template>
									<em v-else class="text-muted"> no date formate</em>
								</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Country</div>
								<div class="">{{ companyDetails.currency }}</div>
							</div>
							<div class="d-flex align-items-center mt-3" v-if="companyDetails">
								<div class="mw-150 px-5 text-right text-muted">Time Zone</div>
								<div class="">{{ companyDetails.timezone }}</div>
							</div>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="business_hour">
					<div class="px-4">
						<div class="sub-heading text-capitalize">
							Business Hours
							<v-btn class="mx-2" icon fab small v-on:click="open_dialog.business_hours = true">
								<v-icon color="blue darken-4">mdi-pencil-circle</v-icon>
							</v-btn>
						</div>
						<div class="col-md-8 px-0">
							<p>
								Business hours define the operational hours of your organization. Set business hours to help
								your employees ensure that the activities are carried out at the operational hours of your
								organization.
							</p>
						</div>
						<div class="d-flex align-items-center">
							<div class="mw-150 px-5 text-right text-muted">Week starts on :</div>
							<div class="fw-500" v-if="companyDetails">{{ companyDetails.weak_start }}</div>
						</div>
						<div
							class="d-flex align-items-center mt-3"
							v-if="companyDetails.business_hours_type == 1 || companyDetails.business_hours_type == 2"
						>
							<div class="mw-150 px-5 text-right text-muted">Business Hours :</div>
							<div class="fw-500" v-if="companyDetails">24 Hours</div>
						</div>
						<div class="d-flex mt-3">
							<div class="mw-150 px-5 text-right text-muted">Business Days :</div>
							<div class="width-50 fw-500" v-if="companyDetails">
								<template v-if="companyDetails.business_hours_type == 1"> Monday-Sunday </template>
								<template v-else>
									<!-- {{ workingDays(companyDetails.business_hours) }} -->
									<div class="mt-n2">
										<v-row v-for="(row, index) in companyDetails.business_hours" :key="index" class="mb-3">
											<v-col :class="`my-auto ${!row.status ? 'text-muted' : ''}`"
												><span class="fw-600">{{ row.title }}</span></v-col
											>
											<v-col>
												<div class="d-flex align-items-center">
													<v-switch
														v-model="row.status"
														inset
														hide-details
														class="ma-0"
														color="blue darken-4"
														readonly
													></v-switch>
													<span class="ml-3">
														<template v-if="row.status"> Open </template>
														<template v-else>
															<span class="text-muted"> Closed </span>
														</template>
													</span>
												</div>
											</v-col>
											<template v-if="companyDetails.business_hours_type == 3">
												<v-col :class="`my-auto ${!row.status ? 'text-muted' : ''}`">{{
													row.start_time
												}}</v-col>
												<v-col :class="`my-auto ${!row.status ? 'text-muted' : ''}`">{{ row.end_time }}</v-col>
											</template>
											<template v-else>
												<v-col></v-col>
												<v-col></v-col>
											</template>
										</v-row>
									</div>
								</template>
							</div>
						</div>
					</div>
				</v-tab-item>
				<v-tab-item value="holidays">
					<div class="px-4">
						<div class="sub-heading text-capitalize">Create Holidays</div>
						<div class="mb-3 mt-5 d-flex px-0 col-md-4">
							<div class="d-flex pt-1 pr-5">
								<label class="fw-500">Select year </label>
							</div>
							<v-autocomplete
								:items="holiayYearList()"
								depressed
								hide-details
								outlined
								v-model="holidayYear"
								v-on:change="getsHolidays"
							></v-autocomplete>
						</div>
						<div class="mt-5">
							<div class="sub-heading text-capitalize mb-2">
								Holiday list
								<v-btn v-if="!holidayEdit" class="mx-2" icon fab small v-on:click="holidayEdit = true">
									<v-icon color="blue darken-4">mdi-pencil-circle</v-icon>
								</v-btn>
								<div v-if="holidayEdit" class="float-right mb-2">
									<v-btn
										tile
										depressed
										color="white--text"
										v-on:click="updateCompanyHolidays"
										class="blue darken-4"
										>Save</v-btn
									>
									<v-btn
										depressed
										tile
										color="white--text"
										class="ml-2 orange darken-4"
										v-on:click="holidayEdit = false"
										>Cancel</v-btn
									>
								</div>
							</div>
							<div class="pa-4 blue lighten-5">
								<table>
									<tr>
										<td class="fw-500 px-2 py-1" width="300">Holiday Name</td>
										<td class="fw-500 px-2 py-1" width="200">Holiday Date</td>
									</tr>
									<tr v-for="(data, index) in holidayList" :key="index">
										<td class="fw-500 px-2 py-1" width="300">
											<v-text-field
												class="mt-3"
												hide-details
												outlined
												placeholder="Holiday Name"
												v-model="data.name"
											></v-text-field>
										</td>
										<td class="fw-500 px-2 py-1" width="200">
											<div class="d-flex">
												<div>
													<DatePicker
														hide-details
														:disabled="pageLoading"
														:loading="pageLoading"
														id="retire-date"
														placeholder=""
														v-model="data.date"
														:minDate="minDate"
														:maxDate="maxDate"
													></DatePicker>
													<!-- <v-menu
														v-model="date_menu"
														:close-on-content-click="false"
														transition="scale-transition"
														offset-y
														max-width="290px"
														min-width="auto"
													>
														<template v-slot:activator="{ on, attrs }">
															<v-text-field
																hide-details
																outlined
																placeholder="DD MM YYYY"
																prepend-inner-icon="mdi-calendar"
																v-on="on"
																v-bind="attrs"
                                                                 v-model="data.date"
															></v-text-field>
														</template>
														<v-date-picker
															no-title
															:min="minDate"
															:max="maxDate"
															@input="date_menu = false"
														></v-date-picker>
													</v-menu> -->
												</div>
												<v-icon
													v-if="holidayList.length == index + 1"
													@click="addHoliday()"
													class="ml-3"
													color="green"
													>mdi-plus</v-icon
												>
												<v-icon v-else @click="removeHoliday(index)" class="ml-3" color="red">mdi-minus</v-icon>
											</div>
										</td>
									</tr>
								</table>
							</div>
						</div>
					</div>
				</v-tab-item>
			</v-tabs-items>
		</div>
		<template v-if="companyDetails">
			<UpdateCompanyDetail
				:drawer="open_dialog.company_detail"
				:details="companyDetails"
				v-on:close="open_dialog.company_detail = false"
				v-on:success="getCompanyDetails"
			></UpdateCompanyDetail>
			<SocialInformation
				:drawer="open_dialog.social_info"
				:details="companyDetails"
				v-on:close="open_dialog.social_info = false"
				v-on:success="getCompanyDetails"
			></SocialInformation>
			<LocalInformation
				:drawer="open_dialog.local_information"
				:details="companyDetails"
				v-on:close="open_dialog.local_information = false"
				v-on:success="getCompanyDetails"
			></LocalInformation>
			<BusinessHours
				:drawer="open_dialog.business_hours"
				:details="companyDetails"
				v-on:close="open_dialog.business_hours = false"
				v-on:success="getCompanyDetails"
			></BusinessHours>
		</template>
		<v-file-input
			:disabled="uploading"
			v-on:change="updateFile($event)"
			class="d-none"
			ref="avatar-input"
			accept="image/png, image/jpeg"
		></v-file-input>
	</div>
</template>
<script>
import UpdateCompanyDetail from "@/view/module/setting/company/component/UpdateCompanyDetail";
import SocialInformation from "@/view/module/setting/company/component/SocialInformation";
import LocalInformation from "@/view/module/setting/company/component/LocalInformation";
import BusinessHours from "@/view/module/setting/company/component/BusinessHours";
import { GetCompanyDetails, AddUpdateCompanyHolidays, GetsHolidays } from "@/core/lib/setting.lib";
import { map } from "lodash";
import moment from "moment-timezone";
import { SET_ERROR, SET_MESSAGE } from "@/core/services/store/common.module";
import DatePicker from "@/view/components/DatePicker";
import { UploadFiles } from "@/core/lib/upload.lib";
import { head } from "lodash";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
	name: "CompayDetails",
	components: {
		UpdateCompanyDetail,
		SocialInformation,
		LocalInformation,
		BusinessHours,
		DatePicker,
	},
	data() {
		return {
			date_menu: false,
			holidayYear: new Date().getFullYear(),
			holidayEdit: false,
			pageLoading: false,
			open_dialog: {
				company_detail: false,
				social_info: false,
				local_information: false,
				business_hours: false,
			},
			holidayList: [{ holiday_name: null, date: null }],
			companyTab: "company_detaiils",
			companyDetails: {},
			MonthList: [
				"January",
				"February",
				"March",
				"April",
				"May",
				"June",
				"July",
				"August",
				"September",
				"October",
				"November",
				"December",
			],
			yearList: [],
			select_year: 2022,
			select_month: "January",

			uploading: false,
			company_logo: null,
		};
	},
	methods: {
		addHoliday() {
			this.holidayList.push({ name: null, date: null });
			this.holidayEdit = true;
		},
		removeHoliday(id) {
			this.holidayList.splice(id, 1);
			this.holidayEdit = true;
		},
		getCompanyDetails() {
			const _this = this;
			GetCompanyDetails()
				.then((data) => {
					this.companyDetails = data;
				})
				.catch(() => {
					_this.goBack();
				})
				.finally(() => {
					_this.pageLoading = false;
				});
		},
		formatedAddress(data) {
			let addressArr = [];
			if (data.street_1) {
				addressArr.push(data.street_1);
			}
			if (data.street_2) {
				addressArr.push(data.street_2);
			}
			if (data.unit_number) {
				addressArr.push(data.unit_number);
			}
			if (data.country) {
				addressArr.push(data.country);
			}
			if (data.zip_code) {
				addressArr.push(data.zip_code);
			}
			return addressArr.join(", ");
		},
		workingDays(data) {
			let days = map(data, (row) => {
				return row.title;
			});

			return days.join(", ");
		},
		holiayYearList() {
			let currentYear = new Date().getFullYear();
			let startYear = currentYear - 1;
			let endYear = currentYear + 1;
			let yearList = [];
			for (let i = startYear; i <= endYear; i++) {
				yearList.push(i);
			}
			return yearList;
		},
		updateCompanyHolidays() {
			let formData = {
				year: this.holidayYear,
				holidays: this.holidayList,
			};
			AddUpdateCompanyHolidays(formData)
				.then(() => {
					//this.companyDetails = data;
					this.$store.commit(SET_MESSAGE, [
						{ model: true, message: "Success ! Holidays has been created." },
					]);
					this.getsHolidays();
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		getsHolidays() {
			GetsHolidays(this.holidayYear)
				.then((data) => {
					if (data && data.length > 0) {
						this.holidayList = data;
						this.holidayEdit = false;
					} else {
						this.holidayList = [{ holiday_name: null, date: null }];
						this.holidayEdit = true;
					}
				})
				.catch((error) => {
					this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
				})
				.finally(() => {
					this.pageLoading = false;
				});
		},
		changeImage() {
			const avatar_ref = this.$refs["avatar-input"];
			if (avatar_ref) {
				avatar_ref.$refs["input"].click();
			}
		},
		updateFile(file) {
			if (file) {
				this.uploading = true;
				const formData = new FormData();
				formData.append("files[0][file]", file);
				formData.append("files[0][name]", file.name.split(".").slice(0, -1).join("."));
				formData.append("files[0][suffix]", `.${file.name.split(".").pop()}`);
				UploadFiles(formData)
					.then((response) => {
						const company = head(response);
						if (company.url) {
							this.company_logo = company.url;
							console.log({ url: company.url });
						}
					})
					.catch((error) => {
						this.$store.commit(SET_ERROR, [{ model: true, message: error }]);
					})
					.finally(() => {
						this.uploading = false;
					});
			}
		},
	},
	mounted() {
		this.getCompanyDetails();
		this.getsHolidays();
	},
	computed: {
		minDate() {
			return moment(`${this.holidayYear}-01-01`).format("YYYY-MM-DD");
		},
		maxDate() {
			return moment(`${this.holidayYear}-12-31`).format("YYYY-MM-DD");
		},
	},
};
</script>
