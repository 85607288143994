import { render, staticRenderFns } from "./UnitSetting.vue?vue&type=template&id=5fac5f62&scoped=true&"
import script from "./UnitSetting.vue?vue&type=script&lang=js&"
export * from "./UnitSetting.vue?vue&type=script&lang=js&"
import style0 from "./UnitSetting.vue?vue&type=style&index=0&id=5fac5f62&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5fac5f62",
  null
  
)

export default component.exports