import ApiService from "@/core/services/api.service";

export const GetEmailTemplates = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`setting/email-template`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetEmailTemplate = (templateId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`setting/email-template/${templateId}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateEmailTemplates = (templateId, emailTemplate) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`setting/email-template/${templateId}`, emailTemplate)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const updateEmailTemplateStatus = (templateId, status) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`setting/email-template/${templateId}/status/${status}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetEmailData = (type, typeId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`${type}/${typeId}/mail-data`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetCompanyDetails = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`setting/company`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetBrandingsDetails = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`setting/branding`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetRoles = () => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`role`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateCompanyDetails = (data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`setting/company`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const AddUpdateCompanyHolidays = (data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`holiday`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetsHolidays = (year) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`holiday/${year}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetPermission = (id) => {
	let params = 0;
	if (id) {
		params = {
			id: id,
		};
	}
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`permission`, params)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const CreateRole = (data) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`role`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetUserPermission = (uuid) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`user/${uuid}/permission`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const UpdateRole = (data, roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`role/${roleId}`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const AssignedRolePermission = (data, roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`role/${roleId}/permission`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const PostAssignedRolePermission = (data, roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.post(`assign-permission-role/${roleId}/permission`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const AssignedUserPermission = (data, userId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.patch(`user/${userId}/permission`, { permission: data })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetRole = (roleId) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`role/${roleId}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const GetLongUrl = (short_url) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.query(`long-url`, { short_url })
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};

export const VerifyUrl = (token, data) => {
	return new Promise((resolve, reject) => {
		ApiService.setCustomHeader(token);
		ApiService.put(`verify-user`, data)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
export const GenrateBarcode = (type) => {
	return new Promise((resolve, reject) => {
		ApiService.setHeader();
		ApiService.get(`barcode-generate/${type}`)
			.then(({ data }) => {
				resolve(data);
			})
			.catch(({ response }) => {
				reject(response);
			});
	});
};
